.policyContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  .policyItemSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px auto;
    padding: 24px;
    gap: 12px;
    border: 1px solid #a9a9a9;
    border-radius: 8px;
  }
}
