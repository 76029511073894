.billMemberListItem{
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
    border-color: transparent;
  &:hover {
    border-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
}

.selected{
  background-color: rgba(144, 173, 248, 0.47);

}